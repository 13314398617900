import apiCall from '@/utils/api'

const state = {
	adminUser: {},
	adminUsers: [],
    adminUserPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    adminUserLoader: false
};

const getters = {
    adminUserLoader: (state) => state.adminUserLoader,
	adminUsers: (state) => state.adminUsers,
	adminUser: (state) => state.adminUser,
    adminUserPagination: (state) => state.adminUserPagination
}

const actions = {
    async fetchAdminUsers({commit}, page) {
		const response = await apiCall({url: `/api/admin?type=user&page=${page}`, method: 'GET' });
		commit('setAdminUsers', response)
        commit('stopAdminUserLoader', response)
	},
    async fetchAdminUser({commit}, itemId) {
		const response = await apiCall({url: `/api/adminUser/${itemId}`, method: 'GET' });
		commit('setAdminUser', response)
        commit('stopAdminUserLoader', response)
	},
    async filterAdminUsers({commit, state},resp){
		commit('setAdminUsers', resp)
	},
	async startAdminUserLoader({commit, state},resp){
		commit('startAdminUserLoader', resp)
	},
	async stopAdminUserLoader({commit, state},resp){
		commit('stopAdminUserLoader', resp)
	},
};

const mutations = {
	setAdminUsers: (state, adminUsers) => {
		state.adminUsers = adminUsers.data
		state.adminUserPagination.current_page = adminUsers.current_page
		state.adminUserPagination.total = adminUsers.total
		state.adminUserPagination.per_page = adminUsers.per_page
	},
	setAdminUser: (state, adminUser) => {
		state.adminUser = adminUser
	},
    startAdminUserLoader: (state) => state.adminUserLoader = true,
	stopAdminUserLoader: (state) => state.adminUserLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
