import apiCall from '@/utils/api'

const state = {
	edible: {},
	edibles: [],
    ediblePagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    edibleLoader: false
};

const getters = {
    edibleLoader: (state) => state.edibleLoader,
	edibles: (state) => state.edibles,
	edible: (state) => state.edible,
    ediblePagination: (state) => state.ediblePagination
}

const actions = {
    async fetchEdibles({commit}, page) {
		const response = await apiCall({url: `/api/edible?page=${page}`, method: 'GET' });
		commit('setEdibles', response)
        commit('stopLoader', response)
	},
    async fetchEdible({commit}, itemId) {
		const response = await apiCall({url: `/api/edible/${itemId}`, method: 'GET' });
		commit('setEdible', response)
        commit('stopLoader', response)
	},
    async filterEdibles({commit, state},resp){
		commit('setEdibles', resp)
	},
	async startEdibleLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopEdibleLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setEdibles: (state, edibles) => {
		state.edibles = edibles.data
		state.ediblePagination.current_page = edibles.current_page
		state.ediblePagination.total = edibles.total
		state.ediblePagination.per_page = edibles.per_page
	},
	setEdible: (state, edible) => {
		state.edible = edible
	},
    startLoader: (state) => state.edibleLoader = true,
	stopLoader: (state) => state.edibleLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
