import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store/index'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/home')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
    //beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/flower',
    name: 'Flower',
    component: () => import(/* webpackChunkName: "about" */ '../views/flower.vue'),
    //beforeEnter: ifAuthenticated,
  },
  {
    path: '/flower/:slug',
    name: 'FlowerDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/flowerDetails.vue'),
    //beforeEnter: ifAuthenticated,
  },
  {
    path: '/pre-roll',
    name: 'Preroll',
    component: () => import(/* webpackChunkName: "about" */ '../views/preroll.vue'),
    //beforeEnter: ifAuthenticated,
  },
  {
    path: '/pre-roll/:slug',
    name: 'PrerollDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/prerollDetails.vue'),
    //beforeEnter: ifAuthenticated,
  },
  {
    path: '/concentrates',
    name: 'Concentrate',
    component: () => import(/* webpackChunkName: "about" */ '../views/concentrate.vue'),
    //beforeEnter: ifAuthenticated,
  },
  {
    path: '/concentrates/:slug',
    name: 'ConcentrateDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/concentrateDetails.vue'),
    //beforeEnter: ifAuthenticated,
  },
  {
    path: '/edibles',
    name: 'Edible',
    component: () => import(/* webpackChunkName: "about" */ '../views/edible.vue'),
    //beforeEnter: ifAuthenticated,
  },
  {
    path: '/edibles/:slug',
    name: 'EdibleDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/edibleDetails.vue'),
    //beforeEnter: ifAuthenticated,
  },
  {
    path: '/vapes',
    name: 'Vape',
    component: () => import(/* webpackChunkName: "about" */ '../views/vape.vue'),
    //beforeEnter: ifAuthenticated,
  },
  {
    path: '/vapes/:slug',
    name: 'VapeDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/vapeDetails.vue'),
    //beforeEnter: ifAuthenticated,
  },
  {
    path: '/cultivation',
    name: 'Cultivation',
    component: () => import(/* webpackChunkName: "about" */ '../views/cultivation.vue'),
    //beforeEnter: ifAuthenticated,
  },
  {
    path: '/cultivation/:slug',
    name: 'CultivationDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/cultivationDetails.vue'),
    //beforeEnter: ifAuthenticated,
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue'),
    //beforeEnter: ifAuthenticated,
  },
  {
    path: '/FAQs',
    name: 'FAQs',
    component: () => import(/* webpackChunkName: "FAQs" */ '../views/FAQ.vue'),
    //beforeEnter: ifAuthenticated,
  },
  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    component: () => import(/* webpackChunkName: "terms-of-use" */ '../views/termsOfUse.vue'),
    //beforeEnter: ifAuthenticated,
  },
  {
    path: '/wishlist',
    name: 'Wishlist',
    component: () => import(/* webpackChunkName: "about" */ '../views/wishlist.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import(/* webpackChunkName: "about" */ '../views/cart.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "about" */ '../views/checkout.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/profile.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin.vue'),
    beforeEnter: ifAuthenticated,
  },
]

const router = new VueRouter({
  routes
})

export default router
