import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        //dark: true,
        themes: {
            light: {
                primary: '#000',
                secondary: '#08f',
                accent: '#D21F26',
                error: '#b71c1c',
                background: "#EEEEEE",
                header: "#FFFFFF"
            },
            dark: {
                primary: '#AFC82A',
                secondary: '#08f',
                accent: '#D21F26',
                error: '#b71c1c',
                background: "#121212",
                header: "#272727"
            },
        },
    },
});
