import apiCall from '@/utils/api'

const state = {
	vape: {},
	vapes: [],
    vapePagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    vapeLoader: false
};

const getters = {
    vapeLoader: (state) => state.vapeLoader,
	vapes: (state) => state.vapes,
	vape: (state) => state.vape,
    vapePagination: (state) => state.vapePagination
}

const actions = {
    async fetchVapes({commit}, page) {
		const response = await apiCall({url: `/api/vape?page=${page}`, method: 'GET' });
		commit('setVapes', response)
        commit('stopLoader', response)
	},
    async fetchVape({commit}, itemId) {
		const response = await apiCall({url: `/api/vape/${itemId}`, method: 'GET' });
		commit('setVape', response)
        commit('stopLoader', response)
	},
    async filterVapes({commit, state},resp){
		commit('setVapes', resp)
	},
	async startVapeLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopVapeLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setVapes: (state, vapes) => {
		state.vapes = vapes.data
		state.vapePagination.current_page = vapes.current_page
		state.vapePagination.total = vapes.total
		state.vapePagination.per_page = vapes.per_page
	},
	setVape: (state, vape) => {
		state.vape = vape
	},
    startLoader: (state) => state.vapeLoader = true,
	stopLoader: (state) => state.vapeLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
