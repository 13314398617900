import apiCall from '@/utils/api'

const state = {
	preroll: {},
	prerolls: [],
    prerollPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    prerollLoader: false
};

const getters = {
    prerollLoader: (state) => state.prerollLoader,
	prerolls: (state) => state.prerolls,
	preroll: (state) => state.preroll,
    prerollPagination: (state) => state.prerollPagination
}

const actions = {
    async fetchPrerolls({commit}, page) {
		const response = await apiCall({url: `/api/preroll?page=${page}`, method: 'GET' });
		commit('setPrerolls', response)
        commit('stopLoader', response)
	},
    async fetchPreroll({commit}, itemId) {
		const response = await apiCall({url: `/api/preroll/${itemId}`, method: 'GET' });
		commit('setPreroll', response)
        commit('stopLoader', response)
	},
    async filterPrerolls({commit, state},resp){
		commit('setPrerolls', resp)
	},
	async startPrerollLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopPrerollLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setPrerolls: (state, prerolls) => {
		state.prerolls = prerolls.data
		state.prerollPagination.current_page = prerolls.current_page
		state.prerollPagination.total = prerolls.total
		state.prerollPagination.per_page = prerolls.per_page
	},
	setPreroll: (state, preroll) => {
		state.preroll = preroll
	},
    startLoader: (state) => state.prerollLoader = true,
	stopLoader: (state) => state.prerollLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
