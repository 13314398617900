<template>
  <div>
    <v-app-bar
      style="background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 1));"
      :color="headerColor"
      app
      flat
      scroll-threshold="500"
      fade-img-on-scroll
    >
      <v-app-bar-nav-icon
        :class="titleColor + ' hidden-md-and-up'"
        @click="changeNav()"
      ></v-app-bar-nav-icon>
      <v-btn
        icon
        :class="titleColor"
        v-if="!isAuthenticated"
        @click="changeLoginCardState"
      >
        <v-icon>mdi-heart</v-icon>
      </v-btn>
      <v-btn v-if="isAuthenticated" icon :class="titleColor" to="/wishlist">
        <div v-if="wishlistPagination.total != 0">
          <v-badge
            overlap
            bordered
            color="black white--text"
            :content="wishlistPagination.total"
          >
            <v-icon dark class="universal--text"> mdi-heart </v-icon>
          </v-badge>
        </div>
        <div v-else>
          <v-icon dark class="universal--text"> mdi-heart </v-icon>
        </div>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <router-link to="/" style="text-decoration: none !important">
          <div :class="titleColor">Distrxct</div>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        :class="titleColor"
        v-if="!isAuthenticated"
        @click="changeLoginCardState"
      >
        <v-icon>mdi-shopping</v-icon>
      </v-btn>
      <v-btn v-if="isAuthenticated" icon :class="titleColor" to="/cart">
        <div v-if="cartPagination.total != 0">
          <v-badge
            overlap
            bordered
            color="black white--text"
            :content="cartPagination.total"
          >
            <v-icon dark class="universal--text"> mdi-shopping </v-icon>
          </v-badge>
        </div>
        <div v-else>
          <v-icon dark class="universal--text"> mdi-shopping </v-icon>
        </div>
      </v-btn>
      <v-menu left bottom offset-y v-if="isAuthenticated">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" class="hidden-md-and-up">
            <v-icon dark :class="titleColor"> mdi-account </v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item to="/profile">
            <v-list-item-title>
              <div align="center">Profile</div>
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="signOut">
            <v-list-item-title>
              <div align="center">Sign Out</div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        icon
        v-if="!isAuthenticated"
        @click="changeLoginCardState"
        class="hidden-md-and-up"
      >
        <v-icon dark :class="titleColor"> mdi-account </v-icon>
      </v-btn>
      <template
        v-slot:extension
        v-if="
          $vuetify.breakpoint.mdOnly ||
          $vuetify.breakpoint.lgOnly ||
          $vuetify.breakpoint.xlOnly
        "
      > 
        <v-spacer></v-spacer>
        <v-btn text :class="buttonText" router to="/admin" v-if="isAuthenticated && $can('product_view')"
          >Admin</v-btn>
        <v-btn text :class="buttonText" router to="/about"
          >About Distrxct</v-btn>
        <v-btn text :class="buttonText" router to="/flower">Flower</v-btn>
        <v-btn text :class="buttonText" router to="/pre-roll">Pre-Roll</v-btn>
        <v-btn text :class="buttonText" router to="/concentrates"
          >Concentrates</v-btn
        >
        <v-btn text :class="buttonText" router to="/edibles">Edibles </v-btn>
        <v-btn text :class="buttonText" router to="/vapes">Vapes </v-btn>
        <v-btn text :class="buttonText" router to="/cultivation"
          >Cultivation
        </v-btn>
        <v-btn text :class="buttonText" router to="/FAQs">FAQs </v-btn>
        <!-- <v-btn text :class="buttonText" router to="/blog">Blog </v-btn> -->
        <v-menu bottom offset-y v-if="isAuthenticated">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text :class="buttonText" v-bind="attrs" v-on="on">
              {{getProfile.first_name}}
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item to="/profile">
              <v-list-item-title>
                <div align="center">Profile</div>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="signOut">
              <v-list-item-title>
                <div align="center">Sign Out</div>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          text
          :class="buttonText"
          @click="changeLoginCardState"
          v-if="!isAuthenticated"
          >Sign In / Register
        </v-btn>
        <v-spacer></v-spacer>
      </template>
    </v-app-bar>

    <v-dialog
          v-model="navbar"
          persistent
          transition="dialog-top-transition"
          max-width="700"
          fullscreen
          v-if="$vuetify.breakpoint.xsOnly"
        >
          <v-card class="elevation-0" max-width="700" style="opacity: 0.95">
            <v-list align="center">
              
                <v-list-item>
                  <v-list-item-content>
                    <div align="right">
                      <v-btn icon @click="changeNav()">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item router to="admin" v-if="isAuthenticated && $can('product_view')">
                  <v-list-item-content>
                    <v-list-item-title class="black--text"
                      >Admin</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              <template v-for="(route, index) in routes">
                <div :key="index">
                  <v-list-item router :to="'/'+route.route">
                    <v-list-item-content>
                      <v-list-item-title class="black--text"
                        >{{route.name}}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </template>

            </v-list>
          </v-card>
        </v-dialog>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import Vue from "vue";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import { USER_REQUEST } from "@/store/actions/user";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      routes: [
        {name: "About Distrxct", route: "about"},
        {name: "Flower", route: "flower"},
        {name: "Pre-Roll", route: "pre-roll"},
        {name: "Concentrates", route: "concentrates"},
        {name: "Edibles", route: "edibles"},
        {name: "Vapes", route: "vapes"},
        {name: "Cultivation", route: "cultivation"},
        {name: "FAQs", route: "FAQs"},
        {name: "Blog", route: "blog"},
      ]
    };
  },
  mounted() {
    window.onscroll = () => {
      //this.changeColor();
    };
  },
  watch: {
    darkState(oldval, newval) {
      this.handledarkmode();
    },
  },
  created() {
    this.closeNav()
    window.scrollTo(0, 0);

    if (this.isAuthenticated) {
      this.$store.dispatch(USER_REQUEST);
      this.fetchCarts(1);
      this.fetchWishlists(1);
    }
  },
  methods: {
    ...mapActions([
      "closeNav","changeLoginCardState", "fetchCarts", "fetchWishlists", "changeNav"]),
    signOut: function () {
      this.$store.dispatch(AUTH_LOGOUT).then((response) => {
        this.$router.go("/");
      });
    },
    handledarkmode() {
      if (this.darkState == true) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    },
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "darkState",
      "isAuthenticated",
      "headerColor",
      "titleColor",
      "buttonText",

      "cartPagination",
      "wishlistPagination",
      "navbar"
    ]),
  },
};
</script>