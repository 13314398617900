import apiCall from '@/utils/api'

const state = {
	adminOrder: {},
	adminOrders: [],
    adminOrderPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    adminOrderLoader: false
};

const getters = {
    adminOrderLoader: (state) => state.adminOrderLoader,
	adminOrders: (state) => state.adminOrders,
	adminOrder: (state) => state.adminOrder,
    adminOrderPagination: (state) => state.adminOrderPagination
}

const actions = {
    async fetchAdminOrders({commit}, page) {
		const response = await apiCall({url: `/api/admin?type=order&page=${page}`, method: 'GET' });
		commit('setAdminOrders', response)
        commit('stopAdminOrderLoader', response)
	},
    async fetchAdminOrder({commit}, itemId) {
		const response = await apiCall({url: `/api/adminOrder/${itemId}`, method: 'GET' });
		commit('setAdminOrder', response)
        commit('stopAdminOrderLoader', response)
	},
    async filterAdminOrders({commit, state},resp){
		commit('setAdminOrders', resp)
	},
	async startAdminOrderLoader({commit, state},resp){
		commit('startAdminOrderLoader', resp)
	},
	async stopAdminOrderLoader({commit, state},resp){
		commit('stopAdminOrderLoader', resp)
	},
};

const mutations = {
	setAdminOrders: (state, adminOrders) => {
		state.adminOrders = adminOrders.data
		state.adminOrderPagination.current_page = adminOrders.current_page
		state.adminOrderPagination.total = adminOrders.total
		state.adminOrderPagination.per_page = adminOrders.per_page
	},
	setAdminOrder: (state, adminOrder) => {
		state.adminOrder = adminOrder
	},
    startAdminOrderLoader: (state) => state.adminOrderLoader = true,
	stopAdminOrderLoader: (state) => state.adminOrderLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
