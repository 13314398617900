import apiCall from '@/utils/api'

const state = {
	concentrate: {},
	concentrates: [],
    concentratePagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    concentrateLoader: false
};

const getters = {
    concentrateLoader: (state) => state.concentrateLoader,
	concentrates: (state) => state.concentrates,
	concentrate: (state) => state.concentrate,
    concentratePagination: (state) => state.concentratePagination
}

const actions = {
    async fetchConcentrates({commit}, page) {
		const response = await apiCall({url: `/api/concentrate?page=${page}`, method: 'GET' });
		commit('setConcentrates', response)
        commit('stopConcentrateLoader', response)
	},
    async fetchConcentrate({commit}, itemId) {
		const response = await apiCall({url: `/api/concentrate/${itemId}`, method: 'GET' });
		commit('setConcentrate', response)
        commit('stopConcentrateLoader', response)
	},
    async filterConcentrates({commit, state},resp){
		commit('setConcentrates', resp)
	},
	async startConcentrateLoader({commit, state},resp){
		commit('startConcentrateLoader', resp)
	},
	async stopConcentrateLoader({commit, state},resp){
		commit('stopConcentrateLoader', resp)
	},
};

const mutations = {
	setConcentrates: (state, concentrates) => {
		state.concentrates = concentrates.data
		state.concentratePagination.current_page = concentrates.current_page
		state.concentratePagination.total = concentrates.total
		state.concentratePagination.per_page = concentrates.per_page
	},
	setConcentrate: (state, concentrate) => {
		state.concentrate = concentrate
	},
    startConcentrateLoader: (state) => state.concentrateLoader = true,
	stopConcentrateLoader: (state) => state.concentrateLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
