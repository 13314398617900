import apiCall from '@/utils/api'

const state = {
	order: {},
	orders: [],
    orderPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    orderLoader: false
};

const getters = {
    orderLoader: (state) => state.orderLoader,
	orders: (state) => state.orders,
	order: (state) => state.order,
    orderPagination: (state) => state.orderPagination
}

const actions = {
    async fetchOrders({commit}, page) {
		const response = await apiCall({url: `/api/order?page=${page}`, method: 'GET' });
		commit('setOrders', response)
        commit('stopLoader', response)
	},
    async fetchOrder({commit}, itemId) {
		const response = await apiCall({url: `/api/order/${itemId}`, method: 'GET' });
		commit('setOrder', response)
        commit('stopLoader', response)
	},
    async filterOrders({commit, state},resp){
		commit('setOrders', resp)
	},
	async startOrderLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopOrderLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setOrders: (state, orders) => {
		state.orders = orders.data
		state.orderPagination.current_page = orders.current_page
		state.orderPagination.total = orders.total
		state.orderPagination.per_page = orders.per_page
	},
	setOrder: (state, order) => {
		state.order = order
	},
    startLoader: (state) => state.orderLoader = true,
	stopLoader: (state) => state.orderLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
