import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import dotenv from 'dotenv'
import vuetify from './plugins/vuetify'
import {ability} from '@/store'
import { abilitiesPlugin } from '@casl/vue'

Vue.config.productionTip = false
Vue.use(abilitiesPlugin, ability)

dotenv.config()

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

document.addEventListener("deviceready", () => {
  StatusBar.backgroundColorByHexString('#EE7619');
  //NavigationBar.backgroundColorByHexString('#ffffff');

});
