import apiCall from '@/utils/api'

const state = {
	flower: {},
	flowers: [],
    flowerPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    flowerLoader: false
};

const getters = {
    flowerLoader: (state) => state.flowerLoader,
	flowers: (state) => state.flowers,
	flower: (state) => state.flower,
    flowerPagination: (state) => state.flowerPagination
}

const actions = {
    async fetchFlowers({commit}, page) {
		const response = await apiCall({url: `/api/flower?page=${page}`, method: 'GET' });
		commit('setFlowers', response)
        commit('stopLoader', response)
	},
    async fetchFlower({commit}, itemId) {
		const response = await apiCall({url: `/api/flower/${itemId}`, method: 'GET' });
		commit('setFlower', response)
        commit('stopLoader', response)
	},
    async filterFlowers({commit, state},resp){
		commit('setFlowers', resp)
	},
	async startFlowerLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopFlowerLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setFlowers: (state, flowers) => {
		state.flowers = flowers.data
		state.flowerPagination.current_page = flowers.current_page
		state.flowerPagination.total = flowers.total
		state.flowerPagination.per_page = flowers.per_page
	},
	setFlower: (state, flower) => {
		state.flower = flower
	},
    startLoader: (state) => state.flowerLoader = true,
	stopLoader: (state) => state.flowerLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
