import apiCall from '@/utils/api'

const state = {
	cultivation: {},
	cultivations: [],
    cultivationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    cultivationLoader: false
};

const getters = {
    cultivationLoader: (state) => state.cultivationLoader,
	cultivations: (state) => state.cultivations,
	cultivation: (state) => state.cultivation,
    cultivationPagination: (state) => state.cultivationPagination
}

const actions = {
    async fetchCultivations({commit}, page) {
		const response = await apiCall({url: `/api/cultivation?page=${page}`, method: 'GET' });
		commit('setCultivations', response)
        commit('stopLoader', response)
	},
    async fetchCultivation({commit}, itemId) {
		const response = await apiCall({url: `/api/cultivation/${itemId}`, method: 'GET' });
		commit('setCultivation', response)
        commit('stopLoader', response)
	},
    async filterCultivations({commit, state},resp){
		commit('setCultivations', resp)
	},
	async startCultivationLoader({commit, state},resp){
		commit('startCultivationLoader', resp)
	},
	async stopCultivationLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setCultivations: (state, cultivations) => {
		state.cultivations = cultivations.data
		state.cultivationPagination.current_page = cultivations.current_page
		state.cultivationPagination.total = cultivations.total
		state.cultivationPagination.per_page = cultivations.per_page
	},
	setCultivation: (state, cultivation) => {
		state.cultivation = cultivation
	},
    startCultivationLoader: (state) => state.cultivationLoader = true,
	stopLoader: (state) => state.cultivationLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
