import apiCall from '../../utils/api'

const state = {
	loginCardShow: false,
	navbar: false
};

const getters = {
	loginCardState: (state) => state.loginCardShow,
	navbar: (state) => state.navbar,
};

const actions = {
	async changeLoginCardState({commit}) {
		commit('updateCard')
	},
	async changeNav({commit}) {
		commit('updateNav')
	},
	async closeNav({commit}){
		commit('closeNav')
	},
};

const mutations = {
	updateCard: (state) => (state.loginCardShow = !state.loginCardShow),
	updateNav: (state) => (state.navbar = !state.navbar),
	closeNav: (state) => (state.navbar = false)
};

export default {
	state,
	getters,
	actions,
	mutations
}