<template>
  <v-app>
    <Navbar v-if="['PageNotFound', 'ResetPass'].indexOf($route.name) == -1" />
    <v-main color="white">
      <router-view></router-view>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import Navbar from "@/components/layout/navbar";
import Footer from '@/components/layout/footer'
import { mapGetters, mapActions } from "vuex";

export default {
  name: 'App',

  components: {
    Navbar,
    Footer
  },
  created() {
    this.setTheme()
  },
  data: () => ({
    //
  }),
  methods: {
    setTheme(){
      if (this.darkState == true) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    },
  },
  computed: {
    ...mapGetters(["darkState"]),
  },
};
</script>