import Vue from 'vue'
import Vuex from 'vuex'

import abilities from './modules/abilities'
import user from './modules/user'
import auth from './modules/auth'
import header from './modules/header'
import loginCard from './modules/loginCard'
import darkMode from './modules/darkMode'

import cart from './modules/cart'
import wishlist from './modules/wishlist'
import order from './modules/order'

import flower from './modules/flower'
import preroll from './modules/preroll'
import concentrate from './modules/concentrate'
import edible from './modules/edible'
import vape from './modules/vape'
import cultivation from './modules/cultivation'

import adminUser from './modules/adminUser'
import adminOrder from './modules/adminOrder'

import VuexPersist from 'vuex-persist'
import { abilityPlugin, ability as appAbility } from './ability'

Vue.use(Vuex)

export const ability = appAbility

const vuexLocalStorage = new VuexPersist({
  key: 'Distrxct', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  filter: mutation => (true)
})

export default new Vuex.Store({
  plugins: [
    vuexLocalStorage.plugin,
    abilityPlugin
  ],

  modules: {
    abilities,
    user,
    auth,
    header,
    loginCard,
    darkMode,

    cart,
    wishlist,
    order,

    flower,
    preroll,
    concentrate,
    edible,
    vape,
    cultivation,

    adminUser,
    adminOrder
  },
  strict: false,
})
